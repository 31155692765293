import * as React from 'react';
import { Form } from 'react-final-form';
import { Box, Button, InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { TextInput, NullableBooleanInput, useListContext } from 'react-admin';

const PostFilterForm = ({ filters }) => {
    const {
        displayedFilters,
        filterValues,
        setFilters,
        hideFilter
    } = useListContext();

    if (!displayedFilters.main) return null;

    const onSubmit = (values) => {
        if (Object.keys(values).length > 0) {
            setFilters(values);
        } else {
            hideFilter("main");
        }
    };

    const resetFilter = () => {
        setFilters({}, []);
    };

    return (
        <div>
            <Form onSubmit={onSubmit} initialValues={filterValues}>
                {({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <Box display="flex" alignItems="flex-end" mb={1}>
                            <Box component="span" mr={2}>
                                {/* Full-text search filter. We don't use <SearchFilter> to force a large form input */}
                                {
                                    filters.map((filter) => {
                                        return (
                                            <Box component="span" mr={2}>
                                                {filter}
                                            </Box>

                                        )
                                    })
                                }

                            </Box>

                            <Box component="span" mr={2} mb={1.5}>
                                <Button variant="outlined" color="primary" type="submit">
                                    Filter
                                </Button>
                            </Box>
                            <Box component="span" mb={1.5}>
                                <Button variant="outlined" onClick={resetFilter}>
                                    Close
                                </Button>
                            </Box>
                        </Box>
                    </form>
                )}
            </Form>
        </div>
    );
};
export default PostFilterForm;