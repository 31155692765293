import { Box, Typography } from '@mui/material';
import 'jspdf-autotable';
import * as React from "react";
import {
    ArrayField, ArrayInput, AutocompleteInput, BooleanField, BooleanInput, Create, Datagrid, DataProviderContext, DateField,
    DateInput, DateTimeInput, DeleteButton, Edit, EditButton, ExportButton, FormDataConsumer, FormTab, List, NumberInput, RadioButtonGroupInput, ReferenceField,
    ReferenceInput, SelectInput, Show, ShowButton, SimpleForm, SimpleFormIterator,
    SimpleShowLayout, TabbedForm, TextField, TextInput, TopToolbar, useGetOne, useNotify
} from 'react-admin';
import QRCode from "react-qr-code";
import CustomField from "../../Components/CustomField";
import CustomTag from "../../Components/CustomTag";
import EditBailmentInputFields from '../../Components/EditBailmentInputFields';
import FetchInputField from '../../Components/FetchInputField';
import PrintPdfButton from "../../Components/PrintPdfButton";
import PostFilterForm from '../../Components/PostFilterForm';
import PostFilterButton from '../../Components/PostFilterButton';
import CustomFilterInput from '../../Components/CustomFilterInput';

const Title = ({ record }) => {
    return <span>Shipment Details {record ? `#${record.id}` : ''}</span>;
};
const rowStyle = (record, index) => ({
    backgroundColor: record?.edit?.isEdited && !record?.edit?.isApproved  ? '#f4eadc' : 'white',
    border: 'none'
    });
const ShipmentShowActions = ({ basePath, data, resource }) => (
    <TopToolbar>
        <EditButton basePath={basePath} record={data} />
        {/* Add your custom actions */}
        <PrintPdfButton />
    </TopToolbar>
);

const Filters = [
    <TextInput source="expressNumbers" label="Search Express Number" alwaysOn />,
    <TextInput source="trackingNumber" label="Search Tracking Number" alwaysOn />,
    <TextInput source="destination.phone" label="Recepient's Number" alwaysOn />,
     <SelectInput label={`Transportation`} source={`method`} choices={[

        { id: 'air', name: 'Air' },
        { id: 'sea', name: 'Sea' },
    ]} />
    ,
    <SelectInput label={`Status`} source={`status`} choices={[
        { id: 'pending', name: 'Pending' },
        { id: 'in-transit', name: 'In-transit' },
        { id: 'arrived', name: 'Arrived' },


    ]} />,
    <SelectInput label={`Mode`} source={`mode`} choices={[
        { id: 'express', name: 'Express' },
        { id: 'normal', name: 'Normal' },

    ]} />,
    <CustomFilterInput label={'Item Category'} source={'bailment'} />
    
];
export const ShipmentShow = (props) => {

    const { data } = useGetOne('Bookings', props.id);


    return (
        <Show actions={<ShipmentShowActions />} aside={<div style={{ height: "200px", width: "200px" }} id={`qr`}><QRCode size={120} value={props.id} /></div>} {...props}>
            <SimpleShowLayout >

                <TextField source="id" />

                <TextField source="origin.country" label={`From`} />
                <TextField source="destination.country" label={`To`} />
                <ReferenceField source="userId" reference="Users">
                    <TextField source="fullname" />
                </ReferenceField>
                <TextField label={`Receiver's Address`} source="destination.detailedAddress" />
                <TextField label={`Receiver's Email`} type={`email`} source="destination.email" />
                <TextField label={`Receiver's Phone`} source="destination.phone" />
                {/* <TextField label={`Receiver's Fullname`} source="destination.fullname" />                 */}
                <TextField source="paymentStatus" />
                <ReferenceField source="containerId" label={`Depature Date`} reference="Containers">
                    <DateField source="depatureDate" />
                </ReferenceField>

                <BooleanField source="inWarehouse" label={"Arrived in Warehouse"} valueLabelTrue={`Yes`} valueLabelFalse={`No`} />
                <BooleanField source="received" label={"Shipment received"} valueLabelTrue={`Yes`} valueLabelFalse={`No`} />
                <TextField source="price" />
                {/* <ReferenceField source="bookedFor" label={`Booked for`} reference="Users">
                <TextField source="fullname" />
                </ReferenceField> */}

                <TextField source="value" label={`Value Added Service (USD)`} />
                {data?.method === 'sea' ? <>

                    <ReferenceField source="containerId" reference="Containers">
                        <TextField source="id" />
                    </ReferenceField>
                </> :
                    <ReferenceField source="flightId" reference="Flights">
                        <TextField source="id" />
                    </ReferenceField>

                }
                                
                                <TextField source="mode" label={`Delivery Mode`} />


                <BooleanField source="edit.isEdited" valueLabelTrue={`Yes`} valueLabelFalse={`No`} label={'Edited'} />
                <BooleanField source="edit.isApproved" valueLabelTrue={`Yes`} valueLabelFalse={`No`} label={'Approve Edit'} />
                <CustomTag label={`Status`} />
                <ArrayField source="bailment">
                    <Datagrid>

                        <TextField source="expressNumber" label={`expressNumber`} />
                        <TextField source="category" label={`type`} />
                        <TextField source="item" label={`Item Name`} />
                        <TextField source="quantity" label={`quantity`} />
                        <TextField source="unit" label={`unit`} />
                        <TextField source="price" label={`price`} />
                    </Datagrid>
                </ArrayField>


            </SimpleShowLayout>
        </Show>
    )
}

const totalFees = (rate, quantity, valueAddedService) => {
    return parseFloat(rate) * parseFloat(quantity) * parseFloat(valueAddedService)


}

const ListActions = () => (
    <Box width="100%">
      <TopToolbar>
        <PostFilterButton />
        <ExportButton />
      </TopToolbar>
      <PostFilterForm  filters={Filters}/>
    </Box>
  );

export const ShipmentList = props => {
    const dataProvider = React.useContext(DataProviderContext)

    return (
        <List  actions={<ListActions  />} {...props} sort={{ field: 'creationDate', order: 'DESC' }}>
            <Datagrid rowStyle={rowStyle} rowClick="edit">
                <TextField source="id" />
                <ReferenceField source="userId" reference="Users">
                    <TextField source="fullname" />
                </ReferenceField>
                <TextField source="origin.country" label={`From`} />
                <TextField source="destination.country" label={`To`} />
                {/* <ReferenceField source="userId" reference="Bookings" label={'Bailment'}>
                    <TextField source="fullname" /> 
                </ReferenceField> */}
                <DateField source="creationDate" label={`Booking Time`} />
                <CustomField label={`Depature Date`} source={`depatureDate`} />
                <CustomField label={`Arrival Date`} source={`expectedArrivalDate`} />


                <BooleanField source="inWarehouse" label={"Arrived in Warehouse"} valueLabelTrue={`Yes`} valueLabelFalse={`No`} />


                <TextField source="mode" label={`Mode`} />
                <CustomTag label={`Status`} />
                <CustomField label={`Container/Flight`} source={`name`} />

                <EditButton label={false} />
                <DeleteButton label={false} />
            </Datagrid>
        </List>
    );
}

export const ShipmentEdit = props => {
    const dataProvider = React.useContext(DataProviderContext)
    const [transportMethod, setTransportMethod] = React.useState()
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState();
    const [values, setValues] = React.useState([]);



    React.useEffect(() => {
        dataProvider.getOne('Bookings', { id: props.id })
            .then(({ data }) => {
                setTransportMethod(data.method);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            })

    }, []);


    const transform = data => {
        const price = data.bailment.reduce((prev, curr) => {

            return prev + curr.price

        }, 0)
        console.log(price);
        return {
            ...data,
            price: price
        }
    };

    React.useEffect(() => {

        dataProvider.getList('Bailment', {

            pagination: { page: 1, perPage: 10000 },
            sort: { field: 'createdate', order: 'DESC' },
            //  filter: { creationDate: aMonthAgo.toISOString() }

        })
            .then(({ data }) => {
                setValues(data);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            })
    }, []);
    return (
        <>
            {
                !loading &&

                <Edit title={<Title />} {...props}>
                    <TabbedForm>
                        <FormTab label="Origin">
                            <TextInput disabled initialValue={`TC` + Date.now().toString()} source="trackingNumber" />

                            <ReferenceInput filterToQuery={searchText => ({ email: searchText })}
                                source="userId" reference="Users">
                                <AutocompleteInput optionText="email" optionValue='userId' />
                            </ReferenceInput>
                            <RadioButtonGroupInput defaultValue={'air'} label={"Transportation Method"} source="method" choices={[
                                { id: 'air', name: 'Air' },
                                { id: 'sea', name: 'Sea' },

                            ]} />
                            <RadioButtonGroupInput defaultValue={'normal'} label={"Delivery Mode"} source="mode" choices={[
                                { id: 'normal', name: 'Normal' },
                                { id: 'express', name: 'Express' },

                            ]} />
                            {transportMethod && transportMethod.toLowerCase() === 'sea' ?
                                <ReferenceInput label={`Assign Container`} source="containerId" reference="Containers" >
                                    <AutocompleteInput optionText="name" />
                                </ReferenceInput>
                                :
                                transportMethod && transportMethod.toLowerCase() === 'air' ?
                                    <ReferenceInput label={`Assign Flight`} source="flightId" reference="Flights" >
                                        <AutocompleteInput optionText="name" />
                                    </ReferenceInput>
                                    : null
                            }

                            <ReferenceInput filterToQuery={searchText => ({ country: searchText })}
                                source="origin.country" label={'Country'} reference="Warehouse">
                                <SelectInput optionText="country" optionValue="country" />

                            </ReferenceInput>
                            <ReferenceInput filterToQuery={searchText => ({ city: searchText })}
                                source="origin.city" label='City' reference="Warehouse">
                                <SelectInput optionText="city" optionValue="city" />

                            </ReferenceInput>
                            <ReferenceInput filterToQuery={searchText => ({ detailedAddress: searchText })}
                                source="origin.detailedAddress" label='Address' reference="Warehouse">
                                <SelectInput optionText="detailedAddress" optionValue="detailedAddress" />

                            </ReferenceInput>
                            <ReferenceInput filterToQuery={searchText => ({ email: searchText })}
                                source="origin.email" label='Email' reference="Warehouse">
                                <SelectInput optionText="email" optionValue="email" />

                            </ReferenceInput>
                            <ReferenceInput filterToQuery={searchText => ({ phone: searchText })}
                                source="origin.phone" label='Phone' reference="Warehouse">
                                <SelectInput optionText="phone" optionValue="phone" />

                            </ReferenceInput>
                            <ReferenceInput filterToQuery={searchText => ({ fullname: searchText })}
                                source="origin.fullname" label='Fullname' reference="Warehouse">
                                <SelectInput optionText="fullname" optionValue="fullname" />

                            </ReferenceInput>

                            <ArrayInput source="bookedFor" label={`Participant(s)`} >
                                <SimpleFormIterator>
                                    <TextInput label={`Phone Number`} />

                                </SimpleFormIterator>
                            </ArrayInput>
                        </FormTab>
                        <FormTab label="Destination">
                            <TextInput label={`Receiver's Country`} source="destination.country" />
                            <TextInput label={`Receiver's City`} source="destination.city" />
                            <TextInput label={`Receiver's Address`} source="destination.detailedAddress" />
                            <TextInput label={`Receiver's Email`} type={`email`} source="destination.email" />
                            <TextInput label={`Receiver's Phone`} source="destination.phone" />
                            <TextInput label={`Receiver's Fullname`} source="destination.fullname" />
                        </FormTab>
                        <FormTab label="Bailment">
                            <ArrayInput source="bailment">
                                <SimpleFormIterator>
                                    <TextInput source="expressNumber" label={`expressNumber`} />

                                    <FormDataConsumer>
                                        {({ formData, getSource, scopedFormData, ...rest }) =>
                                            <>
                                                <EditBailmentInputFields bailment={values} scope={scopedFormData} source={getSource} transport={formData?.method} {...rest} />


                                            </>
                                        }
                                    </FormDataConsumer>
                                </SimpleFormIterator>
                            </ArrayInput>
                            <BooleanInput source="cleared" label={`Cleared for Shipping`} />
                            <BooleanInput source="inWarehouse" label={"Arrived in Warehouse"} valueLabelTrue={`Yes`} valueLabelFalse={`No`} />
                            <BooleanInput source="received" label={"Shipment received"} valueLabelTrue={`Yes`} valueLabelFalse={`No`} />
                            <TextInput source="remarks" multiline />
                        </FormTab>
                        <FormTab label="Costs">
                            {/* <TextInput source="price" /> */}
                            <SelectInput label={`Value Added Service (USD)`} source={`value`} choices={[
                                { id: 0.5, name: 'Phone cover + protector -- $ 0.5' },
                                { id: 16, name: 'Packaging -- $ 1' },
                                { id: 1, name: '$1 = ¥500' },
                                { id: 2, name: '$2 = ¥1000' },
                                { id: 5, name: '$5 = ¥5000' },
                            ]} />
                            {/* <CustomValueOption  source={`value`}/> */}
                            <TextInput source="method" />
                            <SelectInput source="paymentMethod" choices={[{ id: 'MPESA', name: 'MPESA' }, { id: 'Bank Transfer', name: 'Bank Transfer' }, { id: 'Cash', name: 'Cash' }]} />
                            <RadioButtonGroupInput source="paymentStatus" choices={[
                                { id: 'unpaid', name: 'Unpaid' },
                                { id: 'paid', name: 'Paid' },
                                { id: 'refunded', name: 'Refunded' },
                            ]} />
                            <DateTimeInput source={'creationDate'} />

                        </FormTab>
                        <FormTab label="Misc">
                            <BooleanField valueLabelTrue={`Yes`} valueLabelFalse={`No`} source="edit.isEdited" label={'Edited'} />
                            <BooleanInput source="edit.isApproved" label={'Approve Edit'} />
                        </FormTab>















                    </TabbedForm>
                </Edit>
            }
        </>
    )
};

export const ShipmentCreate = props => {
    const dataProvider = React.useContext(DataProviderContext)
    const [transportMethod, setTransportMethod] = React.useState()
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState();
    const [values, setValues] = React.useState();
    const notify = useNotify();
    // const refresh = useRefresh();
    // const redirect = useRedirect();

    const transform = data => {
        const price = data.bailment.reduce((prev, curr) => {

            return prev + curr.price

        }, 0)
        console.log(price);
        return {
            ...data,
            price: price
        }
    };

    React.useEffect(() => {

        dataProvider.getList('Bailment', {

            pagination: { page: 1, perPage: 10000 },
            sort: { field: 'createdate', order: 'DESC' },
            //  filter: { creationDate: aMonthAgo.toISOString() }

        })
            .then(({ data }) => {
                setValues(data);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            })
    }, []);


    const onSuccess = ({ data }) => {
        // notify(`Computing Total Price`)





        notify(`Creating Shipment`)




        console.log(data);





    };

    const onFailure = (error) => {
        notify(`Could not edit post: ${error.message}`);
        // redirect('/posts');
    }

    return (
        <Create transform={transform} undoable={true} mutationMode="pessimistic" onSuccess={onSuccess} onFalure={onFailure} {...props}>
            <TabbedForm>
                <FormTab label="Origin">
                    <TextInput disabled initialValue={`TC` + Date.now().toString()} source="id" label={'Tracking Number'} />
                    <ReferenceInput label={`Assign Container`} source="containerId" reference="Containers" >
                        <AutocompleteInput optionText="name" />
                    </ReferenceInput>
                    <ReferenceInput label={`Assign Flight`} source="flightId" reference="Flights" >
                        <AutocompleteInput optionText="name" />
                    </ReferenceInput>
                    <ReferenceInput filterToQuery={searchText => ({ email: searchText })}
                        source="userId" reference="Users">
                        <AutocompleteInput optionText="email" optionValue='userId' />
                    </ReferenceInput>
                    <RadioButtonGroupInput defaultValue={'air'} label={"Transportation Method"} source="method" choices={[
                        { id: 'air', name: 'Air' },
                        { id: 'sea', name: 'Sea' },

                    ]} />
                            <RadioButtonGroupInput defaultValue={'normal'} label={"Delivery Mode"} source="mode" choices={[
                                { id: 'normal', name: 'Normal' },
                                { id: 'express', name: 'Express' },

                            ]} />

                    <ReferenceInput filterToQuery={searchText => ({ country: searchText })}
                        source="origin.country" label={'Country'} reference="Warehouse">
                        <SelectInput optionText="country" optionValue="country" />

                    </ReferenceInput>
                    <ReferenceInput filterToQuery={searchText => ({ city: searchText })}
                        source="origin.city" label='City' reference="Warehouse">
                        <SelectInput optionText="city" optionValue="city" />

                    </ReferenceInput>
                    <ReferenceInput filterToQuery={searchText => ({ detailedAddress: searchText })}
                        source="origin.detailedAddress" label='Address' reference="Warehouse">
                        <SelectInput optionText="detailedAddress" optionValue="detailedAddress" />

                    </ReferenceInput>
                    <ReferenceInput filterToQuery={searchText => ({ email: searchText })}
                        source="origin.email" label='Email' reference="Warehouse">
                        <SelectInput optionText="email" optionValue="email" />

                    </ReferenceInput>
                    <ReferenceInput filterToQuery={searchText => ({ phone: searchText })}
                        source="origin.phone" label='Phone' reference="Warehouse">
                        <SelectInput optionText="phone" optionValue="phone" />

                    </ReferenceInput>
                    <ReferenceInput filterToQuery={searchText => ({ fullname: searchText })}
                        source="origin.fullname" label='Fullname' reference="Warehouse">
                        <SelectInput optionText="fullname" optionValue="fullname" />

                    </ReferenceInput>

                    <ArrayInput source="bookedFor" label={`Participant(s)`} >
                        <SimpleFormIterator>
                            <TextInput label={`Phone Number`} />

                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>
                <FormTab label="Destination">
                    <TextInput label={`Receiver's Country`} source="destination.country" />
                    <TextInput label={`Receiver's City`} source="destination.city" />
                    <TextInput label={`Receiver's Address`} source="destination.detailedAddress" />
                    <TextInput label={`Receiver's Email`} type={`email`} source="destination.email" />
                    <TextInput label={`Receiver's Phone`} source="destination.phone" />
                    <TextInput label={`Receiver's Fullname`} source="destination.fullname" />
                </FormTab>
                <FormTab label="Bailment">
                    <ArrayInput source="bailment">
                        <SimpleFormIterator>
                            <TextInput source="expressNumber" label={`expressNumber`} />

                            <FormDataConsumer>
                                {({ formData, getSource, scopedFormData, ...rest }) =>
                                    <>
                                        <FetchInputField bailment={values} scope={scopedFormData} source={getSource} transport={formData?.method} {...rest} />


                                    </>
                                }
                            </FormDataConsumer>
                        </SimpleFormIterator>
                    </ArrayInput>
                    <BooleanInput source="cleared" label={`Cleared for Shipping`} />
                    <BooleanInput source="inWarehouse" label={"Arrived in Warehouse"} valueLabelTrue={`Yes`} valueLabelFalse={`No`} />
                    <BooleanInput source="received" label={"Shipment received"} valueLabelTrue={`Yes`} valueLabelFalse={`No`} />
                    <TextInput source="remarks" multiline />
                </FormTab>
                <FormTab label="Costs">
                    {/* <TextInput source="price" /> */}
                    <SelectInput label={`Value Added Service (USD)`} source={`value`} choices={[
                        { id: 0.5, name: 'Phone cover + protector -- $ 0.5' },
                        { id: 16, name: 'Packaging -- $ 1' },
                        { id: 1, name: '$1 = ¥500' },
                        { id: 2, name: '$2 = ¥1000' },
                        { id: 5, name: '$5 = ¥5000' },
                    ]} />
                    {/* <CustomValueOption  source={`value`}/> */}
                    <TextInput source="method" />
                    <SelectInput source="paymentMethod" choices={[{ id: 'MPESA', name: 'MPESA' }, { id: 'Bank Transfer', name: 'Bank Transfer' }, { id: 'Cash', name: 'Cash' }]} />
                    <RadioButtonGroupInput source="paymentStatus" choices={[
                        { id: 'unpaid', name: 'Unpaid' },
                        { id: 'paid', name: 'Paid' },
                        { id: 'refunded', name: 'Refunded' },
                    ]} />
                    <DateTimeInput source={'creationDate'} />

                </FormTab>



                <FormTab label="Misc">
                    <BooleanInput source="edit.isEdited" label={'Edited'} />
                    <BooleanInput source="edit.isApproved" label={'Approve Edit'} />
                </FormTab>












            </TabbedForm>
        </Create>
    )
};