import * as React from "react";
import { useDataProvider, Loading, Error, TextField } from 'react-admin';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';

const BailmentsList = ({ record }) => {
  const dataProvider = useDataProvider();
  const [bailments, setBailments] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const [expanded, setExpanded] = React.useState(false);

  React.useEffect(() => {
    if (record) {
      const filter = record.id.startsWith('F') ? { flightId: record.id } : { containerId: record.id };
      dataProvider.getList('Bookings', {
        pagination: { page: 1, perPage: 100 },
        sort: { field: 'id', order: 'ASC' },
        filter,
      })
      .then(({ data }) => {
        setBailments(data);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
    }
  }, [record, dataProvider]);

  if (loading) return <Loading />;
  if (error) return <Error />;

  // Count occurrences of each category
  const categoryCounts = {};
  bailments.forEach(each => {
    each.bailment.forEach(item => {
      if (categoryCounts[item.category]) {
        categoryCounts[item.category]++;
      } else {
        categoryCounts[item.category] = 1;
      }
    });
  });

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const categories = Object.entries(categoryCounts);

  return (
    <div>
      {categories.slice(0, 1).map(([category, count]) => (
        <div key={category} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
          <div style={{ flex: 1, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            <TextField record={{ category }} source="category" />
          </div>
          <Chip label={`+${count}`} style={{ marginLeft: '8px' }} />
          {categories.length > 1 && (
            <Button 
              onClick={handleExpandClick} 
              style={{ backgroundColor: '#000', color: '#fff', marginLeft: '8px' }}
            >
              {expanded ? <BiChevronUp /> : <BiChevronDown />}
            </Button>
          )}
        </div>
      ))}
      {expanded && categories.slice(1).map(([category, count]) => (
        <div key={category} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
          <div style={{ flex: 1, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            <TextField record={{ category }} source="category" />
          </div>
          <Chip label={`+${count}`} style={{ marginLeft: '8px' }} />
        </div>
      ))}
    </div>
  );
};

export default BailmentsList;