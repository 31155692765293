import * as React from "react";
import { SelectInput, useDataProvider, useRecordContext } from 'react-admin';

const CustomFilterInput = ({ label, source }) => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const [choices, setChoices] = React.useState([]);

  React.useEffect(() => {
    dataProvider.getList('Bailment', {
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'id', order: 'ASC' },
      filter: {},
    }).then(({ data }) => {
      setChoices(data.map(bailment => ({ id: bailment.id, name: bailment.category })));
    });
  }, [dataProvider]);

  return  (
    <SelectInput label={label} source={source} choices={choices} />
  ) ;
}

export default CustomFilterInput;